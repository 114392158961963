import CreateOrganisationForm from 'components/OrganisationConfiguration/CreateOrganisationForm';
// import InviteUsersToJoinOrganisation from 'components/OrganisationConfiguration/InviteUsers';
import SetupBillingPrompt from 'components/OrganisationConfiguration/SetupBillingPrompt';
import { ORGANISATION_CREATION_SKIP_BILLING } from 'env';
import EmptyLayout from 'layouts/EmptyLayout';
import { Button } from 'opencosmos-ui';
import { useState } from 'react';
import type { IUserWithPermissions } from 'services/api/portal/administration/api/types';
import { Navbar } from 'ui/Navbar';
import { useLocalisation } from 'utils/hooks/useLocalisation';

// 50px here is the height of the navbar
const CUSTOM_HEIGHT = `calc(100vh - 50px)`;

enum ConfigurationSteps {
  'JoinOrCreateOrganisation',
  'CreateOrganisationForm',
  'InviteUsers',
  'SetupBilling',
}

interface ConfigurationOrganisationProps {
  user: IUserWithPermissions;
}
const ConfigureOrganisation = ({ user }: ConfigurationOrganisationProps) => {
  const [currentStep, setCurrentStep] = useState<ConfigurationSteps>(
    ConfigurationSteps.JoinOrCreateOrganisation
  );

  const { translate } = useLocalisation();

  const handleCreateOrganisation = () => {
    if (ORGANISATION_CREATION_SKIP_BILLING) {
      window.location.href = '/data/project/catalog';
    } else {
      setCurrentStep(ConfigurationSteps.SetupBilling);
    }
  };

  return (
    <EmptyLayout>
      <Navbar
        bgClassName={'mission-page-header'}
        hideThemesInUserMenu
        showOnlyLogout
      />
      <div
        className="flex justify-center items-center flex-col"
        style={{ height: CUSTOM_HEIGHT }}
      >
        <div className="w-96 p-5 bg-surface dark:bg-surface-dark shadow-xl">
          {currentStep === ConfigurationSteps.JoinOrCreateOrganisation && (
            <>
              <h4 className="mb-4 font-bold text-lg">
                {translate(
                  'onboarding.createOrganisation.noOrganisationFound.title'
                )}
              </h4>
              <p className="mb-10">
                {translate(
                  'onboarding.createOrganisation.noOrganisationFound.description'
                )}
              </p>
              <Button
                intent="primary"
                size="base"
                onPress={() => {
                  setCurrentStep(ConfigurationSteps.CreateOrganisationForm);
                }}
                className={'text-item-accent-contrast hover:text-item-contrast'}
              >
                {translate('onboarding.createOrganisation.buttonPrompt')}
              </Button>
            </>
          )}

          {currentStep === ConfigurationSteps.CreateOrganisationForm && (
            <CreateOrganisationForm
              handleSubmit={handleCreateOrganisation}
              handleSkip={() => {
                setCurrentStep(ConfigurationSteps.InviteUsers);
              }}
              user={user}
              submitLabel={translate(
                `onboarding.createOrganisation.form.buttons.${
                  ORGANISATION_CREATION_SKIP_BILLING
                    ? 'next'
                    : 'setupBillingButtonText'
                }`
              )}
            />
          )}

          {/* TODO: Use this after invite users BE is ready */}
          {/* {currentStep === ConfigurationSteps.InviteUsers && (
            <InviteUsersToJoinOrganisation
              handleSubmit={() => {
                setCurrentStep(ConfigurationSteps.SetupBilling);
              }}
              handleSkip={() => {
                setCurrentStep(ConfigurationSteps.SetupBilling);
              }}
            />
          )} */}

          {currentStep === ConfigurationSteps.SetupBilling && (
            <SetupBillingPrompt />
          )}
        </div>
      </div>
    </EmptyLayout>
  );
};

export default ConfigureOrganisation;
