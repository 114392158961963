import type { Dispatch, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';
import { Header } from 'datacosmos/components/Header';
import { Map } from 'datacosmos/components/Map';
import { MiniMap } from 'datacosmos/components/MiniMap';
import Toolbar from 'datacosmos/components/Toolbar';
import { useMap } from 'datacosmos/stores/MapProvider';
import { Routes } from 'datacosmos/components/Routes';
import {
  routeParamProjectId,
  routeProjectItems,
  routeTasking,
  routeApplication,
  routeCatalog,
} from 'datacosmos/components/routePath';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { Layers } from 'datacosmos/components/Layers';
import { TaskingLegend } from 'datacosmos/components/TaskingLegend';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';
import BandAlgebra from 'datacosmos/components/BandAlgebra';
import classNames from 'classnames';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import type { IApplicationCatalogContext } from 'datacosmos/stores/ApplicationCatalogContext';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import InstalledAppIndex from 'datacosmos/components/Applications/InstalledAppIndex';
import { useViewMode } from 'datacosmos/utils/hooks/useViewMode';
import { useViews } from 'datacosmos/stores/ViewsProvider';
import ViewDescriptionCard from 'datacosmos/components/ViewDescriptionCard/ViewDescriptionCard';
import Button from '_molecules/Button/Button';
import { useTimeSeries } from 'datacosmos/stores/TimeSeriesProvider';
import LinePixelModeChart from './Layers/PixelMode/LinePixelModeChart';
import WavelengthPixelModeChart from './Layers/PixelMode/WavelengthPixelModeChart';
import MainAppTour from './Tour/MainAppTour';
import { SHOW_MAIN_APP_TOUR } from 'env';
import PixelSpectralSignatureModeChart from './Layers/PixelMode/SpectralSignatureModeChart';
import { zIndexValues } from 'opencosmos-ui/constants';

export const Home = () => {
  const { leftMenuOpen, rightMenuOpen } = useActivePage();
  const { isDarkmode } = useTheme();
  const [isMinimapShown, setIsMinimapShown] = useState(true);

  return (
    <div
      className={classNames(
        'openapp-data openapp-data-root openapp-data-base max-viewport-height',
        { 'bp4-dark': isDarkmode }
      )}
      style={{ overflow: 'hidden' }}
    >
      <DataCosmosControls
        isMinimapShown={isMinimapShown}
        setIsMinimapShown={setIsMinimapShown}
        isDarkmode={isDarkmode}
      >
        <Map
          leftMenuOpen={leftMenuOpen}
          rightMenuOpen={rightMenuOpen}
          isMinimapShown={isMinimapShown}
        />
      </DataCosmosControls>
    </div>
  );
};

const DataCosmosControls = ({
  children,
  isMinimapShown,
  setIsMinimapShown,
  isDarkmode,
}: {
  children: React.ReactNode;
  isMinimapShown: boolean;
  setIsMinimapShown: Dispatch<SetStateAction<boolean>>;
  isDarkmode: boolean;
}) => {
  const { isMinimal } = useViewMode();

  if (isMinimal) {
    return (
      <MinimalModeControls
        isMinimapShown={isMinimapShown}
        setIsMinimapShown={setIsMinimapShown}
        isDarkmode={isDarkmode}
      >
        {children}
      </MinimalModeControls>
    );
  }

  return (
    <FullControls
      isMinimapShown={isMinimapShown}
      setIsMinimapShown={setIsMinimapShown}
      isDarkmode={isDarkmode}
    >
      {children}
    </FullControls>
  );
};

const MinimalModeControls = ({
  children,
  isMinimapShown,
  setIsMinimapShown,
  isDarkmode,
}: {
  children: React.ReactNode;
  isMinimapShown: boolean;
  setIsMinimapShown: Dispatch<SetStateAction<boolean>>;
  isDarkmode: boolean;
}) => {
  const { leftMenuOpen, rightMenuOpen } = useActivePage();
  const { panTo, currentCenter, minimapContainerRef } = useMap();
  const { currentView } = useViews();

  return (
    <>
      <Header leftMenuOpen={leftMenuOpen} />
      {children}
      <ViewDescriptionCard view={currentView} />

      <MiniMap
        lat={currentCenter?.lat}
        lng={currentCenter?.lng}
        rightMenuOpen={rightMenuOpen}
        handleSetCoordinates={panTo}
        setIsMinimapShown={setIsMinimapShown}
        isMinimapShown={isMinimapShown}
        isMinimal={true}
        isDarkmode={isDarkmode}
        miniMapRef={minimapContainerRef}
      />

      {!isMinimapShown && (
        <Button
          icon="ChevronLeftDouble"
          text={'Expand minimap'}
          size={32}
          className="absolute right-2 bottom-12 dark:text-item-dark-contrast bg-transparent dark:bg-transparent"
          onPress={() => {
            setIsMinimapShown(true);
          }}
        />
      )}
    </>
  );
};

const FullControls = ({
  children,
  isMinimapShown,
  setIsMinimapShown,
  isDarkmode,
}: {
  children: React.ReactNode;
  isMinimapShown: boolean;
  setIsMinimapShown: Dispatch<SetStateAction<boolean>>;
  isDarkmode: boolean;
}) => {
  const { leftMenuOpen, rightMenuOpen, isAlgebraMenuOpen } = useActivePage();
  const { currentScenario } = useProjects();
  const { selectedInstalledApp } = useApplicationCatalog();
  const { isLayersMenuOpen, layers } = useMapLayers();
  const map = useMap();

  const { isTimeSeriesToggled } = useTimeSeries();

  /**
   * Hide the minimap if the time series is toggled.
   */
  useEffect(() => {
    if (isTimeSeriesToggled) {
      setIsMinimapShown(false);
    }
  }, [isTimeSeriesToggled, setIsMinimapShown]);

  return (
    <>
      <Header leftMenuOpen={leftMenuOpen} />
      {SHOW_MAIN_APP_TOUR && <MainAppTour />}
      <Toolbar
        routeScenario={routeProjectItems}
        defaultScenarioPath={routeProjectItems.replace(
          ':' + routeParamProjectId,
          currentScenario ? currentScenario.id : 'PLACEHOLDER'
        )}
        routeTasking={routeTasking}
        routeApplication={routeApplication}
        routeCatalog={routeCatalog}
        setIsMinimapShown={setIsMinimapShown}
        isMinimapShown={isMinimapShown}
      />

      {leftMenuOpen && (
        <div className="sidebar-content" style={{ zIndex: zIndexValues.map }}>
          <Routes />
        </div>
      )}

      {children}

      <Toolbar
        routeScenario={routeProjectItems}
        defaultScenarioPath={routeProjectItems.replace(
          ':' + routeParamProjectId,
          currentScenario ? currentScenario.id : 'PLACEHOLDER'
        )}
        routeTasking={routeTasking}
        routeApplication={routeApplication}
        routeCatalog={routeCatalog}
        right
        setIsMinimapShown={setIsMinimapShown}
        isMinimapShown={isMinimapShown}
      />

      {rightMenuOpen && (
        <RightMenu
          selectedInstalledApp={selectedInstalledApp}
          isLayersMenuOpen={isLayersMenuOpen}
          isAlgebraMenuOpen={isAlgebraMenuOpen}
        />
      )}

      <TaskingLegend
        showOnRoute={routeTasking}
        rightMenuOpen={rightMenuOpen}
        layers={layers}
      />

      <LinePixelModeChart rightMenuOpen={rightMenuOpen} />
      <WavelengthPixelModeChart rightMenuOpen={rightMenuOpen} />
      <PixelSpectralSignatureModeChart rightMenuOpen={rightMenuOpen} />

      <MiniMap
        lat={map.currentCenter?.lat}
        lng={map.currentCenter?.lng}
        rightMenuOpen={rightMenuOpen}
        handleSetCoordinates={map.panTo}
        setIsMinimapShown={setIsMinimapShown}
        isMinimapShown={isMinimapShown}
        isMinimal={false}
        isDarkmode={isDarkmode}
        miniMapRef={map.minimapContainerRef}
      />
    </>
  );
};

const RightMenu = ({
  selectedInstalledApp,
  isLayersMenuOpen,
  isAlgebraMenuOpen,
}: {
  selectedInstalledApp: IApplicationCatalogContext['selectedInstalledApp'];
  isLayersMenuOpen: boolean;
  isAlgebraMenuOpen: boolean;
}) => {
  let content = <></>;
  if (selectedInstalledApp) {
    content = <InstalledAppIndex app={selectedInstalledApp} />;
  } else {
    if (isLayersMenuOpen) {
      content = <Layers />;
    } else {
      content = isAlgebraMenuOpen ? <BandAlgebra /> : <Routes />;
    }
  }
  return (
    <div
      className="sidebar-content"
      style={{ position: 'absolute', right: 60, zIndex: 10 }}
    >
      <div
        id="right-menu"
        style={{ maxHeight: 'inherit', height: '100%', overflowY: 'auto' }}
      >
        {content}
      </div>
    </div>
  );
};
