import {
  postOrganisation,
  type OrganisationJoinPolicy,
} from '_api/administration/service';
import { Button, Input, ListBoxItem, Select } from 'opencosmos-ui';
import { useMemo, useState } from 'react';
import type { IUserWithPermissions } from 'services/api/portal/administration/api/types';
import { isValidCompanyEmail } from 'utils/common/stringUtils';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import useLocalStorage from 'utils/hooks/useLocalStorage';

interface CreateOrganisationFormProps {
  handleSubmit: () => void;
  handleSkip: () => void;
  user: IUserWithPermissions;
  submitLabel: string;
}
const CreateOrganisationForm = ({
  handleSubmit,
  handleSkip,
  user,
  submitLabel,
}: CreateOrganisationFormProps) => {
  const { translate } = useLocalisation();
  const userEmail = user.email.split('@')[1];

  const [organisationName, setOrganisationName] = useState<string>('');
  const [isCreatingOrganisation, setIsCreatingOrganisation] =
    useState<boolean>(false);
  const [domainErrorMessage, setDomainErrorMessage] = useState<
    string | undefined
  >(undefined);
  const [organisationDomain, setOrganisationDomain] =
    useState<string>(userEmail);
  const [joinPolicy, setJoinPolicy] = useState<OrganisationJoinPolicy>('JOIN');

  const [, setCurrentOrganisation] = useLocalStorage<number | undefined>(
    'currentOrganisation',
    undefined
  );
  const handleClick = async (type: string) => {
    setIsCreatingOrganisation(true);
    const resultOrg = await postOrganisation({
      body: {
        name: organisationName,
        domain: organisationDomain,
        join_policy: joinPolicy,
      },
    });

    if (!resultOrg.success) {
      setIsCreatingOrganisation(false);
      return;
    }

    setCurrentOrganisation(resultOrg.data?.id);
    if (type === 'skip') {
      handleSkip();
      setIsCreatingOrganisation(false);
      return;
    }
    handleSubmit();
    setIsCreatingOrganisation(false);
  };

  const isOrganisationDomainValid = useMemo(() => {
    if (!organisationDomain) {
      setDomainErrorMessage(
        translate(
          'onboarding.createOrganisation.form.errors.domainConnotBeEmpty'
        )
      );
      return false;
    }

    const isValidCompanyDomain = isValidCompanyEmail(organisationDomain);
    if (!isValidCompanyDomain) {
      setDomainErrorMessage(
        translate('onboarding.createOrganisation.form.errors.invalidDomain')
      );
      return false;
    }

    setDomainErrorMessage(undefined);
    return isValidCompanyDomain;
  }, [organisationDomain, translate]);

  return (
    <>
      <h4 className="mb-4 font-bold text-lg">
        {translate('onboarding.createOrganisation.form.title')}
      </h4>
      <div className="flex flex-col gap-4 mb-10">
        <Input
          type="text"
          label={{
            position: 'top',
            text: translate('onboarding.createOrganisation.form.fields.name'),
          }}
          autoFocus
          onChange={(e) => setOrganisationName(e.target.value)}
        />

        <Input
          type="text"
          label={{
            position: 'top',
            text: translate('onboarding.createOrganisation.form.fields.domain'),
          }}
          value={organisationDomain}
          onChange={(e) => setOrganisationDomain(e.target.value)}
          errorMsg={domainErrorMessage}
        />

        <Select
          label={translate(
            'onboarding.createOrganisation.form.fields.howToJoin.title'
          )}
          fill
          placeholder="Select"
          defaultSelectedKey={'JOIN'}
          selectedKey={joinPolicy}
          disabledKeys={['INVITE', 'REQUEST']}
          onSelectionChange={(item) =>
            setJoinPolicy(item as OrganisationJoinPolicy)
          }
        >
          <ListBoxItem id="INVITE">
            {translate(
              'onboarding.createOrganisation.form.fields.howToJoin.options.onInvite'
            )}
          </ListBoxItem>
          <ListBoxItem id="REQUEST">
            {translate(
              'onboarding.createOrganisation.form.fields.howToJoin.options.requestToJoin'
            )}
          </ListBoxItem>
          <ListBoxItem id="JOIN">
            {translate(
              'onboarding.createOrganisation.form.fields.howToJoin.options.join'
            )}
          </ListBoxItem>
        </Select>
      </div>
      <div className={'flex flex-col items-end justify-end gap-2'}>
        <Button
          intent="primary"
          onPress={() => handleClick('invite')}
          isDisabled={
            organisationName?.length === 0 || !isOrganisationDomainValid
          }
          className={'text-item-accent-contrast hover:text-item-contrast'}
          loading={isCreatingOrganisation}
        >
          {submitLabel}
        </Button>

        {/* TODO: Use this after invite users BE is ready */}
        {/* <Button
          isMinimal
          isTransparent
          className={'text-xs'}
          onPress={() => handleClick('skip')}
          isDisabled={organisationName?.length === 0}
        >
          Skip inviting users
        </Button> */}
      </div>
    </>
  );
};

export default CreateOrganisationForm;
