import { getUserInfo } from '_api/authentication/service';
import { FRONTEND_AUTHENTICATION_BASE_URL } from 'env';
import _ from 'lodash';
import type { IUserWithPermissions } from 'services/api/portal/administration/api/types';
import type { AuthenticationBackend } from './useAuthentication';

/**
 * Create a new authentication backend using auth0 as the authentication source
 * @param o options to initialise the auth0 client
 */
export const newOpencosmosAuthBacked =
  async (): Promise<AuthenticationBackend> => {
    return Promise.resolve(new OpencosmosAuthBackend());
  };

/**
 * OpencosmosAuthBacked allows to use a Opencosmos specific auth implementation as a authentication backend
 */
export class OpencosmosAuthBackend {
  /**
   * Returns an up to date token, if one is available, fetching a fresh token in the background if needed
   * @returns will resolve to an up to date token, if possible
   */
  upToDateTokenInBackground = (): Promise<string | undefined> => {
    const baseUrl = FRONTEND_AUTHENTICATION_BASE_URL;

    window.location.href = `${baseUrl}/login?prompt=none&redirect_url=${encodeURIComponent(
      `${window.location.href}`
    )}`;
    return Promise.resolve(undefined);
  };

  /**
   * Starts the login flow, redirecting to the authentication provider
   * @returns will resolve when login succeeds or fails(?)
   */
  login = (): Promise<void> => {
    const baseUrl = FRONTEND_AUTHENTICATION_BASE_URL;

    window.location.href = `${baseUrl}/login?prompt=login&redirect_url=${encodeURIComponent(
      window.location.href
    )}`;

    return Promise.resolve();
  };

  /**
   * Returns information about the user, including the permissions the authentication provider is aware of
   * @returns resolves to the user's information from the authentication backend
   */
  user = async (): Promise<IUserWithPermissions | undefined> => {
    const { data: user } = await getUserInfo({});
    return user?.user;
  };

  /**
   * Logs out/unauthenticates the currently authenticated user/ends the current session
   */
  logout = (): void => {
    const baseUrl = FRONTEND_AUTHENTICATION_BASE_URL;

    window.location.href = `${baseUrl}/logout`;
  };

  /**
   * Checks whether the current user is authenticated
   * @returns resolves to true if the current user is authenticated
   */
  authenticated = async (): Promise<boolean> => {
    const { data: user, errors, success } = await getUserInfo({});
    return success && Boolean(user) && errors.length === 0;
  };
}
