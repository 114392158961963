import MailToButton, { sendEmail } from '_organisms/MailToButton/MailToButton';
import { FEEDBACK_MAIL } from 'constants/contact';
import { Button } from 'opencosmos-ui';
import type { GroupedNavItems } from 'opencosmos-ui/src/navigation/Sidebar/types';
import { useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useAuth } from 'services/auth/AuthWrapper';
import { useMission } from 'services/Missions';
import { UserProfileMenu } from 'ui/UserMenu';
import { getSelectedNavItem } from './helpers';
import { OPERATIONS_ENABLE } from 'env';

const useOpsNav = () => {
  const h = useHistory();
  const { currentMissionId: mid } = useMission();
  const { user } = useAuth();

  const opsMatch = useRouteMatch<{ app: string; path: string }>({
    path: '/:app/:path*',
    exact: false,
  });

  const getFallbackEmail = useCallback(() => {
    if (opsMatch?.params.app === 'ops') return 'openapp-help@open-cosmos.com';
    return FEEDBACK_MAIL;
  }, [opsMatch]);

  const getTeam = useCallback(() => {
    if (opsMatch?.params.app === 'ops') return 'operations';
    return 'datacosmos';
  }, [opsMatch?.params.app]);

  const getUrlTo = useCallback(() => {
    const pathname = window.location.pathname;

    if (!OPERATIONS_ENABLE) {
      return '/';
    }

    if (mid && pathname.startsWith('/ops')) {
      return `/ops/mission/${mid}/`;
    }

    if (!mid && pathname.startsWith('/ops')) {
      return `/ops`;
    }

    return '/';
  }, [mid]);

  const opsNavMain: GroupedNavItems = useMemo(() => {
    if (!OPERATIONS_ENABLE) return {};
    return {
      configuration: {
        items: [
          {
            key: 'library',
            item: 'Library',
            onClick: () => h.push(`/ops/mission/${String(mid)}/library`),
            icon: 'book',
          },
        ],
      },
      planning: {
        items: [
          {
            key: 'gs-scheduling',
            item: 'Ground Station Scheduler',
            icon: 'antenna',
            onClick: () => h.push(`/ops/mission/${String(mid)}/gs-scheduling`),
          },
          {
            key: 'schedule',
            item: 'Schedule',
            onClick: () => h.push(`/ops/mission/${String(mid)}/schedule`),
            icon: 'Calendar',
          },
        ],
      },
      scripting: {
        items: [
          {
            key: 'scripting',
            item: 'Node-RED Scripting',
            onClick: () => h.push(`/ops/mission/${String(mid)}/scripting`),
            icon: 'code',
          },
          {
            key: 'scripts',
            item: 'Python Scripting',
            onClick: () => h.push(`/ops/mission/${String(mid)}/scripts`),
            icon: 'PyScript',
          },
          {
            key: 'runs',
            item: 'Runs',
            onClick: () => h.push(`/ops/mission/${String(mid)}/runs`),
            icon: 'play',
          },
        ],
      },
      operations: {
        items: [
          {
            key: 'telemetry',
            item: 'Telemetry Visualiser',
            onClick: () =>
              h.push(`/ops/telemetry/?var-missionId=${String(mid)}`),
            icon: 'timeline-line-chart',
          },
          {
            key: 'rti',
            item: 'Real Time Interface',
            onClick: () => h.push(`/ops/mission/${String(mid)}/rti`),
            icon: 'time',
          },
          {
            key: 'image-processing',
            item: 'Payload Processing',
            onClick: () =>
              h.push(`/ops/mission/${String(mid)}/pdgs/image-processing`),
            icon: 'cube',
          },
        ],
      },
      assembly_integration_and_tests: {
        items: [
          {
            key: 'gse',
            item: 'Ground Support Equipment',
            icon: 'SensorType',
            onClick: () => h.push(`/ops/mission/${String(mid)}/gse`),
          },
        ],
      },
    };
  }, [h, mid]);

  const opsNavFooter: GroupedNavItems = useMemo(() => {
    return {
      ungrouped: {
        items: [
          {
            key: 'help',
            item: 'Help',
            icon: 'Help',
            onClick: () => {
              window.location.href = '/help';
            },
          },
          {
            key: 'send-feedback',
            item: (
              <MailToButton
                subject="Send feedback"
                fallbackEmail={getFallbackEmail()}
                team={getTeam()}
                className="w-full flex items-center gap-1 !p-0 m-0 hover:text-item-dark"
                iconSize={24}
              />
            ),
            collapsedItem: (
              <Button
                icon="envelope"
                onPress={() => {
                  sendEmail('Send feedback', getFallbackEmail());
                }}
                size="lg"
                isTransparent={true}
                className={'border-none'}
              />
            ),
          },
          {
            key: 'home',
            item: 'Home',
            icon: 'home',
            onClick: () => {
              h.push(getUrlTo());
            },
          },
          {
            divider: {
              above: true,
            },
            key: 'user-menu',
            item: (
              <div className="flex items-center gap-1 h-8">
                <div className="flex rounded-full w-[50px] h-12">
                  <UserProfileMenu />
                </div>
                <div className="flex flex-col">
                  <span className="text-sm"> {user?.name}</span>
                  <span className="text-xs"> {user?.email}</span>
                </div>
              </div>
            ),
            collapsedItem: (
              <div className="flex rounded-full w-[50px] mt-1">
                <UserProfileMenu />
              </div>
            ),
            hideTooltip: true,
          },
        ],
      },
    };
  }, [getFallbackEmail, getTeam, getUrlTo, h, user?.email, user?.name]);

  const selectedItemKey = useMemo(
    () => getSelectedNavItem(opsNavMain, h.location.pathname),
    [h.location.pathname, opsNavMain]
  );

  return {
    opsNavMain,
    opsNavFooter,
    selectedItemKey,
  };
};

export default useOpsNav;
