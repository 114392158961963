import { useState } from 'react';
import type { IconName } from '@blueprintjs/core';
import { Icon } from '@blueprintjs/core';
import s from './index.module.scss';
import { Button } from 'opencosmos-ui';
import { getBillingPortalURL } from '_api/orders/service';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
interface IProps {
  id: number;
  title: string;
  icon: IconName;
  showManageBillingButton?: boolean;
  organisationId?: number;
}

const AdministrationHeader = (props: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleManageBillingClick = async () => {
    if (!props.organisationId) {
      return;
    }
    setLoading(true);
    const { data } = await getBillingPortalURL({
      params: {
        organisationId: props?.organisationId?.toString(),
      },
    });
    if (data?.portal_link) {
      window.open(data?.portal_link, '_blank');
    }
    setLoading(false);
  };

  return (
    <div className={s.container}>
      <div className={`${s.idWrapper} flex justify-between`}>
        <div>
          <Icon icon={props.icon} />
          {props.id}
        </div>
        {props.showManageBillingButton && (
          <Button
            intent="primary"
            icon="DollarSign"
            isMinimal
            iconPlacement="left"
            size="sm"
            className={`p-4 rounded`}
            onPress={handleManageBillingClick}
            isDisabled={loading}
          >
            Manage Billing
            {loading && <Spinner size={'sm'} />}
          </Button>
        )}
      </div>
      <div className={s.title}>{props.title}</div>
    </div>
  );
};

export default AdministrationHeader;
