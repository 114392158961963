import { reduce } from 'lodash/fp';
import type { PermissionType } from '_api/administration/permissions';
import type { IRoleAssignment } from 'services/api/portal/administration/api/types';
import type { UserAssignments } from '_api/administration/types';

export interface IResourcePermission {
  [resourceId: string]: string[];
}

/**
 * This type is used to represent the permissions granted to a user. It is
 * structured as a map of resource types to either a list of permissions or a
 * map of resource IDs to lists of permissions.
 *
 * Only the global type has a string af permissions, all other types have a map
 * of resource IDs to permissions.
 */
export type Permission = {
  [resourceType in PermissionType]?: resourceType extends 'global'
    ? string[]
    : { [key: string]: string[] };
};

export interface IResourcePermissionTree {
  [scopePart: string]: IResourcePermissionTree | boolean;
}

export type PermissionTree = {
  [resourceType in PermissionType]?: resourceType extends 'global'
    ? IResourcePermissionTree
    : { [resourceId: string | number]: IResourcePermissionTree };
};

/**
 * Groups the list of assignments per user
 * @param assignmentList
 * @returns Object with userId as keys and list of assignments as values
 */
export const groupAssignmentsByUser = (assignmentList: IRoleAssignment[]) =>
  reduce<IRoleAssignment, { [userId: string]: IRoleAssignment[] }>(
    (acc, item) => {
      return {
        ...acc,
        [item.userId]: [...(acc[item.userId] || []), item],
      };
    },
    {},
    assignmentList
  );

/* 
    Finds the highest role assigned to the user through sorting by name 
    and filtering the userAssignable roles only. 
    Name starts with numbers indicating priority
  */
export const findHighestPriorityRole = (userAssignments: UserAssignments[]) => {
  if (!userAssignments) return;

  const numberedRoleAssignments = userAssignments.filter(
    (roleAssignment) =>
      !isNaN(parseInt(roleAssignment.role.name.split('-')[0].trim()))
  );

  const sortedAndFilteredAssignments = numberedRoleAssignments.sort((a, b) =>
    b.role.name.localeCompare(a.role.name)
  );

  return sortedAndFilteredAssignments[0]?.role?.name?.split('-')[1]?.trim();
};
