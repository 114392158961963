import { BACKEND_BASE_URL, FRONTEND_AUTHENTICATION_BASE_URL } from 'env';

function getWebsocketBaseUrl() {
  const base = BACKEND_BASE_URL;

  let protocol = 'wss://';

  if (window.location.host.includes('localhost')) {
    protocol = 'ws://';
  }

  if (base.startsWith('/')) {
    return `${protocol}${window.location.host}${base}`;
  } else if (base.startsWith('http')) {
    return base.replace('http', 'ws');
  } else {
    return `${protocol}${base}`;
  }
}

export const hosts = {
  portal: {
    v0: BACKEND_BASE_URL + '/portal/v0',
    v1: BACKEND_BASE_URL + '/portal/v1',
    v2: BACKEND_BASE_URL + '/portal/v2',
  },
  data: {
    v0: BACKEND_BASE_URL + '/data/v0',
    v1: BACKEND_BASE_URL + '/data/v1',
  },
  ops: {
    v0: BACKEND_BASE_URL + '/ops/v0',
    v1: BACKEND_BASE_URL + '/ops/v1',
    noVersion: BACKEND_BASE_URL + '/ops',
  },
  storage: {
    v0: BACKEND_BASE_URL + '/data/v0/storage',
  },
  OSM: {
    search: 'https://nominatim.openstreetmap.org',
  },
  authentication: FRONTEND_AUTHENTICATION_BASE_URL,
};

export const websocketHost = {
  ops: {
    v0: getWebsocketBaseUrl() + '/ops/v0',
  },
};
