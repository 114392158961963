import {
  getSingleImageCovarianceMatrix,
  type CovarianceMatrixData,
} from '_api/sampling/service';
import useStacInfo from '../../utils/hooks/useStacInfo';
import type { StacItem } from 'datacosmos/types/stac-types';
import utils from 'datacosmos/utils/utils';
import { Button } from 'opencosmos-ui';
import { useState } from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import CovarianceModal from './CovarianceModal';
import { toaster } from 'toaster';
import { QAButton } from './QAButton';

type TooltipContentProps = {
  data: StacItem;
  hasQAPermission?: boolean;
};

export const TooltipContent = ({
  data,
  hasQAPermission,
}: TooltipContentProps) => {
  const { observationZenithAngle, sensors } = useStacInfo(data);

  const [isOpenVarianceModal, setIsOpenVarianceModal] =
    useState<boolean>(false);

  const [isCovarianceLoading, setIsCovarianceLoading] =
    useState<boolean>(false);

  const [covarianceMatrixData, setCovarianceMatrixData] =
    useState<CovarianceMatrixData>();

  const handleCalculateVarianceClick = async () => {
    if (!data.collection) {
      return;
    }
    setIsCovarianceLoading(true);
    const { data: matrixData, status } = await getSingleImageCovarianceMatrix({
      params: {
        collectionId: data.collection,
        itemId: data.id,
      },
    });

    if (!matrixData) {
      setIsCovarianceLoading(false);
      if (status !== 200) {
        toaster.show({
          message: translate('datacosmos.fetchErrors.samplingMatrix.cannotGet'),
          intent: 'danger',
        });
      }
      return;
    }

    setIsOpenVarianceModal(true);
    setCovarianceMatrixData(matrixData);
    setIsCovarianceLoading(false);
  };

  const { translate } = useLocalisation();

  return (
    <ul className="flex flex-col gap-2 dark:text-item-dark-contrast">
      <li className="flex items-center gap-2">
        <span>{translate('datacosmos.catalogAndItems.metadata.oza')} </span>
        <span>{observationZenithAngle}</span>
      </li>
      <li className="flex items-center gap-2">
        <span>{translate('datacosmos.catalogAndItems.metadata.sensors')} </span>
        <span>{sensors}</span>
      </li>
      <li className="flex items-center gap-2">
        <span>
          {translate('datacosmos.catalogAndItems.metadata.resolution')}{' '}
        </span>
        <span>
          {data.properties.gsd ? utils.gsdToUnit(data.properties.gsd) : '-'}
        </span>
      </li>
      <li>
        <Button
          fill
          onPress={() => handleCalculateVarianceClick()}
          loading={isCovarianceLoading}
        >
          {translate('datacosmos.catalogAndItems.metadata.calculateCovariance')}{' '}
        </Button>
      </li>
      {hasQAPermission && (
        <li>
          <QAButton item={data} />
        </li>
      )}

      <CovarianceModal
        isOpen={isOpenVarianceModal}
        setIsOpen={setIsOpenVarianceModal}
        covarianceMatrix={covarianceMatrixData}
      />
    </ul>
  );
};
