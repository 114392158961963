import { ColumnLayout } from 'ui/ColumnLayout/ColumnLayout';
import { Column } from 'ui/ColumnLayout/Column';
import { Header } from 'ui/Header';
import { Navbar } from 'ui/Navbar';
import { Screen } from 'ui/Screen';
import { ItemTabContainer } from 'ui/ItemContainer';
import { DateInput } from 'ui/DateInput';
import { ActivitiesMap } from 'pages/ops/Scheduling/components/ActivitiesMap';
import { Text } from 'ui/Text';
import {
  CompositeDetailsItem,
  DetailsItem,
  TabularDetailsItem,
} from 'pages/ops/shared/components/DetailsItem';
import { RowLayout } from 'ui/RowLayout/RowLayout';
import { Row } from 'ui/RowLayout/Row';
import { toFormattedDate, toFormattedTime } from 'utils/common/dateUtils';
import { useTaskingOverviewData } from './useTaskingOverviewData';
import { NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import findSatelliteNameByMissionId from 'utils/findSatelliteNameByMissionId';
import { Loader } from 'ui/Loader';
import UserInfo from '_organisms/UserInfo/UserInfo';
import Textarea from '_atoms/Textarea/Textarea';
import AddActivityModal from 'pages/datacosmos/tasking_overview/AddActivityModal';
import {
  Button,
  DialogTrigger,
  Icon,
  ListBoxItem,
  Select,
  Tooltip,
} from 'opencosmos-ui';
import { Dialog, Popover } from 'react-aria-components';
import type { GenericDetailsItem, RequestType } from '_api/tasking/types';
import { CancelRequest } from '_organisms/CancelRequestPopup/CancelRequest';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import PaginatedtaskingRequestsList from './PaginatedTaskingRequestsList';

const TaskingOverviewScreen = () => {
  const {
    classifiedRequests,
    selectedRequest,
    handleTaskingRequestClick,
    loading,
    userLoading,
    setFilter,
    filter,
    handleMissionIdFilterClick,
    handleOrganisationIdFilterClick,
    handleTypeFilterClick,
    handleProjectIdFilterClick,
    customer,
    isAddActivityModalOpen,
    setIsAddActivityModalOpen,
    refetchTaskingRequests,
    disableAddActivity,
    disableCancelRequest,
    isCancelRequestModalOpen,
    setIsCancelRequestModalOpen,
    allTaskingRequestsMetadata,
    getRequestsByStatus,
  } = useTaskingOverviewData();

  const { translate } = useLocalisation();

  return (
    <Screen>
      <Navbar />
      <ColumnLayout
        cols={2}
        colRatio={[2, 1]}
        horizontalGap={10}
        style={{ overflowY: 'hidden' }}
      >
        <Column style={{ height: '94.5vh', overflowX: 'scroll' }}>
          <ItemTabContainer
            fullWidth={true}
            className="dark:bg-header-dark bg-header"
          >
            <Header margin={{ left: { value: 0 }, right: { value: 'auto' } }}>
              Requests
            </Header>

            <DateInput
              setValue={setFilter.fromDate}
              value={filter.fromDate}
              title="From"
            />
            <DateInput
              setValue={setFilter.toDate}
              value={filter.toDate}
              title="To"
            />
            <DialogTrigger>
              <Button data-testid="open-filters" isMinimal isTransparent>
                {translate('datacosmos.buttons.filters')}
              </Button>
              <Popover className={'bg-surface p-2'} placement="bottom right">
                <Dialog className={'flex flex-col gap-3'}>
                  <Select
                    label={translate('datacosmos.tasking.new.type')}
                    selectedKey={filter?.type ?? ''}
                    placeholder={translate(
                      'datacosmos.tasking.existing.filters.placeholder.type'
                    )}
                    name="type"
                    testid="select-type"
                    onSelectionChange={(val) => {
                      handleTypeFilterClick(val as string);
                    }}
                    fill
                  >
                    <ListBoxItem id={undefined} key={undefined}>
                      All
                    </ListBoxItem>
                    {['MANUAL', 'AUTOMATED'].map((type) => (
                      <ListBoxItem
                        id={type}
                        key={type}
                        data-testid={`type-option-${type}`}
                      >
                        {translate(
                          `datacosmos.tasking.existing.requestType.${
                            type as RequestType
                          }`
                        )}
                      </ListBoxItem>
                    ))}
                  </Select>

                  <Select
                    label={translate('datacosmos.catalogAndItems.project')}
                    selectedKey={filter?.projectId ?? ''}
                    placeholder={translate(
                      'datacosmos.tasking.existing.filters.placeholder.project'
                    )}
                    name="project"
                    testid="select-project"
                    onSelectionChange={(val) => {
                      handleProjectIdFilterClick(val as string);
                    }}
                    fill
                  >
                    <ListBoxItem id={undefined} key={undefined}>
                      All
                    </ListBoxItem>
                    {filter?.availableProjects?.map((project) => (
                      <ListBoxItem
                        id={project.id}
                        key={project.id}
                        data-testid={`project-option-${project.id}`}
                      >
                        {project.name}
                      </ListBoxItem>
                    ))}
                  </Select>

                  <Select
                    label="Mission"
                    selectedKey={filter?.missionId ?? ''}
                    placeholder={translate(
                      'datacosmos.tasking.existing.filters.placeholder.mission'
                    )}
                    name="mission"
                    testid="select-mission"
                    onSelectionChange={(val) => {
                      handleMissionIdFilterClick(val as string);
                    }}
                    fill
                  >
                    <ListBoxItem id={undefined} key={undefined}>
                      All
                    </ListBoxItem>
                    {filter?.availableMissionIds?.map((mission) => (
                      <ListBoxItem
                        id={mission}
                        key={mission}
                        data-testid={`missionId-option-${mission}`}
                      >
                        {mission}
                      </ListBoxItem>
                    ))}
                  </Select>

                  <Select
                    label="Organisation"
                    selectedKey={filter?.organisationId ?? ''}
                    placeholder={translate(
                      'datacosmos.tasking.existing.filters.placeholder.organisation'
                    )}
                    name="organisation"
                    onSelectionChange={(val) =>
                      handleOrganisationIdFilterClick(val as string)
                    }
                    fill
                  >
                    <ListBoxItem id={undefined} key={undefined}>
                      All
                    </ListBoxItem>
                    {filter?.availableOrganisations?.map((org) => (
                      <ListBoxItem id={org.id} key={org.id}>
                        {org.name}
                      </ListBoxItem>
                    ))}
                  </Select>

                  <Button
                    onPress={() => {
                      handleOrganisationIdFilterClick('');
                      handleMissionIdFilterClick('');
                      handleTypeFilterClick('');
                      handleProjectIdFilterClick('');
                    }}
                    fill
                    intent="warning"
                    icon="cross"
                    iconPlacement="left"
                    isMinimal
                    size="sm"
                    className={'my-1'}
                  >
                    {translate('datacosmos.filters.clearAll')}
                  </Button>
                </Dialog>
              </Popover>
            </DialogTrigger>
          </ItemTabContainer>

          {loading ? (
            <Loader />
          ) : (
            <ColumnLayout
              cols={7}
              colRatio={[1, 1, 1, 1, 1, 1, 1]}
              horizontalGap={3}
            >
              <Column
                key={1}
                style={{ overflowY: 'auto', position: 'relative' }}
              >
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.existing.requestStatus.PENDING.description'
                  )}
                >
                  <div className="flex items-center">
                    <Text className="sticky top-0 bg-surface dark:bg-surface-dark">
                      {translate(
                        'datacosmos.tasking.existing.requestStatus.PENDING.title'
                      )}
                    </Text>
                    <Icon icon="Info" />
                  </div>
                </Tooltip>

                <PaginatedtaskingRequestsList
                  hasMore={Boolean(
                    allTaskingRequestsMetadata?.PENDING?.remaining
                  )}
                  data-testid="pending-column"
                  classifiedRequests={classifiedRequests?.pending}
                  handleTaskingRequestClick={handleTaskingRequestClick}
                  getRequestsByStatus={getRequestsByStatus}
                  status="PENDING"
                  cursor={allTaskingRequestsMetadata?.PENDING?.cursor}
                  selectedRequest={selectedRequest}
                />
              </Column>

              <Column
                key={2}
                style={{ overflowY: 'auto', position: 'relative' }}
              >
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.existing.requestStatus.READY.description'
                  )}
                >
                  <div className="flex items-center">
                    <Text className="sticky top-0 bg-surface dark:bg-surface-dark">
                      {translate(
                        'datacosmos.tasking.existing.requestStatus.READY.title'
                      )}
                    </Text>
                    <Icon icon="Info" />
                  </div>
                </Tooltip>

                <PaginatedtaskingRequestsList
                  hasMore={Boolean(
                    allTaskingRequestsMetadata?.READY?.remaining
                  )}
                  classifiedRequests={classifiedRequests?.ready}
                  handleTaskingRequestClick={handleTaskingRequestClick}
                  data-testid="ready-column"
                  getRequestsByStatus={getRequestsByStatus}
                  status="READY"
                  cursor={allTaskingRequestsMetadata?.READY?.cursor}
                  selectedRequest={selectedRequest}
                />
              </Column>

              <Column
                key={3}
                style={{ overflowY: 'auto', position: 'relative' }}
              >
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.existing.requestStatus.ACQUIRING.description'
                  )}
                >
                  <div className="flex items-center">
                    <Text className="sticky top-0 bg-surface dark:bg-surface-dark">
                      {translate(
                        'datacosmos.tasking.existing.requestStatus.ACQUIRING.title'
                      )}
                    </Text>
                    <Icon icon="Info" />
                  </div>
                </Tooltip>
                <PaginatedtaskingRequestsList
                  hasMore={Boolean(
                    allTaskingRequestsMetadata?.ACQUIRING?.remaining
                  )}
                  classifiedRequests={classifiedRequests?.acquiring}
                  data-testid="acquiring-column"
                  handleTaskingRequestClick={handleTaskingRequestClick}
                  getRequestsByStatus={getRequestsByStatus}
                  status="ACQUIRING"
                  cursor={allTaskingRequestsMetadata?.ACQUIRING?.cursor}
                  selectedRequest={selectedRequest}
                />
              </Column>

              <Column
                key={4}
                style={{ overflowY: 'auto', position: 'relative' }}
              >
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.existing.requestStatus.FULFILLED.description'
                  )}
                >
                  <div className="flex items-center">
                    <Text className="sticky top-0 bg-surface dark:bg-surface-dark">
                      {translate(
                        'datacosmos.tasking.existing.requestStatus.FULFILLED.title'
                      )}
                    </Text>
                    <Icon icon="Info" />
                  </div>
                </Tooltip>
                <PaginatedtaskingRequestsList
                  hasMore={Boolean(
                    allTaskingRequestsMetadata?.FULFILLED?.remaining
                  )}
                  classifiedRequests={classifiedRequests?.fulfilled}
                  data-testid="fulfilled-column"
                  handleTaskingRequestClick={handleTaskingRequestClick}
                  getRequestsByStatus={getRequestsByStatus}
                  status="FULFILLED"
                  cursor={allTaskingRequestsMetadata?.FULFILLED?.cursor}
                  selectedRequest={selectedRequest}
                />
              </Column>

              <Column
                key={5}
                style={{ overflowY: 'auto', position: 'relative' }}
              >
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.existing.requestStatus.PARTIALLY_FULFILLED.description'
                  )}
                >
                  <div className="flex items-center">
                    <Text className="sticky top-0 bg-surface dark:bg-surface-dark">
                      {translate(
                        'datacosmos.tasking.existing.requestStatus.PARTIALLY_FULFILLED.title'
                      )}
                    </Text>
                    <Icon icon="Info" />
                  </div>
                </Tooltip>
                <PaginatedtaskingRequestsList
                  hasMore={Boolean(
                    allTaskingRequestsMetadata?.PARTIALLY_FULFILLED?.remaining
                  )}
                  classifiedRequests={classifiedRequests?.partiallyFulfilled}
                  data-testid="partially-fulfilled-column"
                  handleTaskingRequestClick={handleTaskingRequestClick}
                  getRequestsByStatus={getRequestsByStatus}
                  status="PARTIALLY_FULFILLED"
                  cursor={
                    allTaskingRequestsMetadata?.PARTIALLY_FULFILLED?.cursor
                  }
                  selectedRequest={selectedRequest}
                />
              </Column>

              <Column
                key={6}
                style={{ overflowY: 'auto', position: 'relative' }}
              >
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.existing.requestStatus.FAILED.description'
                  )}
                >
                  <div className="flex items-center">
                    <Text className="sticky top-0 bg-surface dark:bg-surface-dark">
                      {translate(
                        'datacosmos.tasking.existing.requestStatus.FAILED.title'
                      )}
                    </Text>
                    <Icon icon="Info" />
                  </div>
                </Tooltip>
                <PaginatedtaskingRequestsList
                  hasMore={Boolean(
                    allTaskingRequestsMetadata?.FAILED?.remaining
                  )}
                  classifiedRequests={classifiedRequests?.failed}
                  data-testid="failed-column"
                  handleTaskingRequestClick={handleTaskingRequestClick}
                  getRequestsByStatus={getRequestsByStatus}
                  status="FAILED"
                  cursor={allTaskingRequestsMetadata?.FAILED?.cursor}
                  selectedRequest={selectedRequest}
                />
              </Column>

              <Column key={7} style={{ overflowY: 'auto' }}>
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.existing.requestStatus.CANCELLED.description'
                  )}
                >
                  <div className="flex items-center">
                    <Text className="sticky top-0 bg-surface dark:bg-surface-dark">
                      {translate(
                        'datacosmos.tasking.existing.requestStatus.CANCELLED.title'
                      )}
                    </Text>
                    <Icon icon="Info" />
                  </div>
                </Tooltip>
                <PaginatedtaskingRequestsList
                  hasMore={Boolean(
                    allTaskingRequestsMetadata?.CANCELLED?.remaining
                  )}
                  classifiedRequests={classifiedRequests?.cancelled}
                  data-testid="cancelled-column"
                  handleTaskingRequestClick={handleTaskingRequestClick}
                  getRequestsByStatus={getRequestsByStatus}
                  status="CANCELLED"
                  cursor={allTaskingRequestsMetadata?.CANCELLED?.cursor}
                  selectedRequest={selectedRequest}
                />
              </Column>
            </ColumnLayout>
          )}
        </Column>

        <Column style={{ overflow: 'auto' }}>
          {!selectedRequest ? (
            <NonIdealState
              icon={IconNames.HELP}
              title="No request selected"
              description="Select a request to view it here"
              className="dark:text-item-dark-contrast text-item-contrast"
            />
          ) : (
            <>
              {!isAddActivityModalOpen && (
                <ActivitiesMap
                  activity={selectedRequest.activities}
                  request={selectedRequest}
                  aspectRatio="auto"
                />
              )}

              <RowLayout rows={2} rowRatio={[0.1, 1]} gap={5}>
                <Row>
                  <Column data-testid="request-details-column">
                    <ItemTabContainer>
                      <Header
                        size="h2"
                        margin={{
                          left: { value: 0 },
                          right: { value: 'auto' },
                        }}
                      >
                        Request
                      </Header>
                      {selectedRequest.status !== 'CANCELLED' &&
                        selectedRequest.status !== 'FULFILLED' &&
                        selectedRequest.status !== 'FAILED' && (
                          <Tooltip
                            content="Tasking requests with past activities cannot be cancelled"
                            isDisabled={!selectedRequest}
                          >
                            <Button
                              className="w-24"
                              isDisabled={disableCancelRequest}
                              onPress={() => setIsCancelRequestModalOpen(true)}
                            >
                              Cancel
                            </Button>
                          </Tooltip>
                        )}
                    </ItemTabContainer>
                    <DetailsItem
                      aria-label="Request id"
                      title="Id:"
                      value={selectedRequest.id}
                    />
                    <DetailsItem
                      aria-label="Request type"
                      title="Type:"
                      value={selectedRequest.type}
                    />
                    <DetailsItem
                      aria-label="Number of activities"
                      title="Number of activities:"
                      value={String(selectedRequest.activities.length)}
                    />
                    <DetailsItem
                      aria-label="Request subject"
                      title="Subject:"
                      value="N/A"
                    />
                    <DetailsItem
                      aria-label="Request region name"
                      title="Region name:"
                      value={selectedRequest.region_name}
                    />
                    <DetailsItem
                      aria-label="Request project name"
                      title="Project name:"
                      value={selectedRequest.project_name}
                    />
                    <DetailsItem
                      aria-label=" Request organisation name"
                      title="Organisation name:"
                      value={selectedRequest.organisation_name}
                    />
                    {selectedRequest.constraints?.length && (
                      <TabularDetailsItem
                        title="Constraints:"
                        value={
                          selectedRequest.constraints as unknown as GenericDetailsItem[]
                        }
                      />
                    )}

                    {selectedRequest.parameters?.objectives?.length && (
                      <TabularDetailsItem
                        title="Objectives:"
                        value={
                          selectedRequest.parameters
                            ?.objectives as unknown as GenericDetailsItem[]
                        }
                      />
                    )}

                    <CompositeDetailsItem
                      title="Customer: "
                      element={
                        <UserInfo
                          user={customer}
                          loading={userLoading}
                          fieldsToShow={['email']}
                        />
                      }
                    />
                    <CompositeDetailsItem
                      title="Notes: "
                      element={
                        <Textarea disabled value={selectedRequest.notes} />
                      }
                    />
                  </Column>
                </Row>

                <Row>
                  <Column data-testid="activities-column">
                    <ItemTabContainer>
                      <Header
                        size="h2"
                        margin={{
                          left: { value: 0 },
                          right: { value: 'auto' },
                        }}
                      >
                        Activities
                      </Header>
                      <Tooltip
                        content="Activities cannot be added to expired tasking requests"
                        isDisabled={!disableAddActivity}
                      >
                        <Button
                          className="w-24"
                          isDisabled={disableAddActivity}
                          onPress={() => setIsAddActivityModalOpen(true)}
                        >
                          Add
                        </Button>
                      </Tooltip>
                    </ItemTabContainer>

                    {selectedRequest.activities.map((a) => (
                      <ItemTabContainer
                        className="justify-between items-center dark:bg-item-dark bg-item"
                        key={a.id}
                      >
                        <Icon icon="Satellite" size={24} />
                        <Text>
                          {findSatelliteNameByMissionId(a.mission_id)}
                        </Text>
                        <Text>{toFormattedDate(a.start_date)}</Text>
                        <Text>{toFormattedTime(a.start_date)}</Text>
                        <Text>-</Text>
                        <Text>{toFormattedTime(a.end_date)}</Text>
                        <Text>{a.status}</Text>
                        <Text>{a?.priority?.toString()}</Text>
                      </ItemTabContainer>
                    ))}
                  </Column>
                </Row>
              </RowLayout>

              <CancelRequest
                isOpen={isCancelRequestModalOpen}
                setIsOpen={setIsCancelRequestModalOpen}
                refetchData={refetchTaskingRequests}
                request={selectedRequest}
              />
            </>
          )}
        </Column>
      </ColumnLayout>

      <AddActivityModal
        isOpen={isAddActivityModalOpen}
        setIsOpen={setIsAddActivityModalOpen}
        request={selectedRequest}
        onSuccess={() => refetchTaskingRequests()}
      />
    </Screen>
  );
};

export default TaskingOverviewScreen;
