import React, { createContext, useContext, useState } from 'react';
import useOpsNav from '../../ops/shared/hooks/nav/useOpsNav';

type OpenappContextType = ReturnType<typeof useOpenappNavProvider>;

const OpenappContext = createContext<OpenappContextType>(
  null as unknown as OpenappContextType
);

export const useOpenappNav = () => useContext(OpenappContext);

const useOpenappNavProvider = () => {
  const opsNav = useOpsNav();

  const [isNavCollapsed, setIsNavCollapsed] = useState<boolean>(true);

  return {
    ...opsNav,
    isNavCollapsed,
    setIsNavCollapsed,
  };
};

const OpenappNavProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <OpenappContext.Provider value={useOpenappNavProvider()}>
      {children}
    </OpenappContext.Provider>
  );
};

export default OpenappNavProvider;
