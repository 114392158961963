import React, { useEffect, useRef, useState } from 'react';
import { filter, find, remove } from 'lodash/fp';
import CollapseLibrary from './CollapseLibrary';
import s from './index.module.scss';
import type { IconName } from '@blueprintjs/core';

export interface ICollapseLibraryStore {
  tagTitle: string;
  tagIcon: IconName;
  tagContent?: React.ReactNode;
  children: React.ReactNode;
  collapsed?: boolean;
  maxHeight?: number;
  handleRefreshClick?: () => void;
}

interface IProps {
  stores: ICollapseLibraryStore[];
}

const CollapseLibraryList = (props: IProps) => {
  const [collapsedItems, setCollapsedItems] = useState<ICollapseLibraryStore[]>(
    []
  );
  const collapseRef = useRef(null);

  useEffect(() => {
    const collapsedList = filter('collapsed', props.stores);

    setCollapsedItems(collapsedList);
  }, [props.stores]);

  const handleItemCollapse = (tagIcon: string) => {
    const oldItem = find(['tagIcon', tagIcon], collapsedItems);

    if (oldItem) {
      const newList = remove(['tagIcon', tagIcon], collapsedItems);
      setCollapsedItems([...newList]);
      return;
    }

    const newItem = find(['tagIcon', tagIcon], props.stores);

    if (!newItem) {
      return;
    }

    setCollapsedItems([...collapsedItems, newItem]);
  };

  return (
    <div ref={collapseRef} className={s.libraryFileExplorerWrapper}>
      {props.stores.map((item) => (
        <CollapseLibrary
          key={item.tagTitle}
          tagTitle={item.tagTitle}
          tagIcon={item.tagIcon}
          tagContent={item.tagContent}
          collapsed={Boolean(find(['tagIcon', item.tagIcon], collapsedItems))}
          collapsedCount={collapsedItems.length}
          handleItemCollapse={handleItemCollapse}
          handleRefreshClick={item.handleRefreshClick}
          maxHeight={item.maxHeight}
        >
          {item.children}
        </CollapseLibrary>
      ))}
    </div>
  );
};

export default CollapseLibraryList;
