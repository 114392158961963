import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { IUserInformation } from 'constants/user/actionTypes';
import { setUserThunks } from 'actions/user/thunks';
import { setSentryUser } from './sentryUser';
import type { IUserWithPermissions } from 'services/api/portal/administration/api/types';
import Cookie from 'js-cookie';

// Contains information about an error encountered during authentication
export interface IAuthError {
  name: string;
  description: string;
}

const REFRESH_TOKEN_TIMEOUT = 3600000; // 1h;

export const useAuthentication = (i: AuthenticationBackendInitialiser) => {
  const [user, setUser] = useState<IUserWithPermissions>();
  const [authBackend, setAuthBackend] = useState<
    AuthenticationBackend | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<IAuthError>();
  const dispatch = useDispatch();
  const [token, setToken] = useState<string>();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  //TODO: This temporarily sets the token from the cookie, in order to have permissions work correctly
  //TODO: REMOVE THIS ONCE BACKEND STARTS CHECKING COOKIES FOR AUTHORIZATION
  const setTokenFromCookies = useCallback(() => {
    const newToken = Cookie.get('Authorization');
    setToken(newToken);
  }, []);

  useEffect(() => {
    const userInformation: IUserInformation = { ...user, token };
    // @ts-expect-error
    dispatch(setUserThunks(userInformation));
    setSentryUser(userInformation);
  }, [dispatch, token, user]);

  useEffect(() => {
    if (!user) return () => {};

    const timeout = setInterval(() => {
      void authBackend?.upToDateTokenInBackground();
    }, REFRESH_TOKEN_TIMEOUT);

    return () => {
      clearInterval(timeout);
    };
  }, [authBackend, user]);

  useEffect(() => {
    const initAuth = async () => {
      setTokenFromCookies();

      if (authBackend === undefined) {
        setAuthBackend(await i());
        return;
      }

      const isAuthed = await authBackend.authenticated();

      setIsAuthenticated(isAuthed);

      if (isAuthed) {
        const u = await authBackend.user();
        setUser(u);
      } else if (Cookie.get('auth.is.authenticated') === 'true') {
        await authBackend.upToDateTokenInBackground();
      } else {
        await authBackend.login({});
      }

      setLoading(false);
    };

    setLoading(true);
    initAuth()
      .then(() => {
        setLoading(false);
      })
      .catch((authError) => {
        type AuthError =
          | { error?: string; error_description?: string }
          | undefined;
        if (
          authError &&
          typeof (authError as AuthError)?.error === 'string' &&
          typeof (authError as AuthError)?.error_description === 'string'
        ) {
          setError({
            name: (authError as AuthError)?.error!,
            description: (authError as AuthError)?.error_description!,
          });
        } else {
          setError({
            name: 'Auth error',
            description: JSON.stringify(authError),
          });
        }
        setLoading(false);
      });
  }, [setTokenFromCookies, authBackend, i]);

  const loginWithRedirect = useCallback(() => {
    return authBackend?.login({});
  }, [authBackend]);

  const logout = useCallback(
    (returnTo?: string) => {
      Object.keys(localStorage).forEach((key) => {
        if (key !== 'main-app-tour-completed') {
          localStorage.removeItem(key);
        }
      });
      return authBackend?.logout(returnTo);
    },
    [authBackend]
  );

  return {
    user,
    loading,
    error,
    //TODO: Token should be removed once backend starts checking cookies for authorization
    token,
    isAuthenticated,
    authBackend,
    loginWithRedirect,
    logout,
  };
};

/**
 * An AuthenticationBackendInitialiser configures/sets up a new authentication backend for this hook to use
 */
export type AuthenticationBackendInitialiser =
  () => Promise<AuthenticationBackend>;

/**
 * An AuthenticationBackend is a client which connects to a specific backend for authentication,
 * e.g. auth0 or Keycloak
 */
export interface AuthenticationBackend {
  // upToDateTokenInBackground returns a non-expired token if possible, without
  // re-authenticating the user i.e. fetching a fresh token in the background if needed
  upToDateTokenInBackground(): Promise<string | undefined>;
  // login starts the user login flow via the authentication provider
  login(currentState: AppState): Promise<void>;
  // authenticated returns true if the current user has already been authenticated
  authenticated(): Promise<boolean>;
  // user returns information about the current user, including their permissions
  user(): Promise<IUserWithPermissions | undefined>;
  // logout logs out (deauthenticates) the current user/ends the current session
  logout(returnTo?: string): void;
}

/**
 * AppState contains the state of the application before a login flow redirect cycle
 */
export interface AppState {
  url?: string;
}
